import React from 'react';
import { Button, Navbar, Container, Card, Row, Col } from "react-bootstrap";
import NavBar from '../NavBar/NavBar';
import Footer from './Footer';
import './LandingPage.css';
import ripple from '../../images/Ripple.svg';
import wave from '../../images/Wave.svg';
import tide from '../../images/Tide.svg';
import rogue from '../../images/Rogue.svg';

function NextPage() {
    return (
        <div>
            <NavBar />
            <div style={{ marginTop: "4rem" }}>
                <Container className="mt-3">
                    <Row>
                        <Col md={6} className="padding-ind">
                            <center>
                                <div className='l-text-sm-ind'>Industry</div>
                                <div className='l-text-focus'>Focus</div>
                            </center>
                        </Col>
                        <Col md={6} className="padding-tech">
                            <div>
                                <li>Deep Tech</li>
                                <li>Gaming Tech</li>
                                <li>Nature Tech</li>
                                <li>Agri Tech</li>
                                <li>Manufacturing Tech</li>
                                <li>DeFi / FinTech</li>
                                <li>Food Tech</li>
                            </div>
                        </Col>
                    </Row >
                </Container ><br />
                <Container className="mt-3">
                    <center><div className='set-sail'>Set sail as entrepreneurial journey</div></center>
                    <Row className='mt-2'>
                        <Col md={3}>
                            <Card className="card-tech">
                                <center><div className="ripple-text">Ripple</div>
                                    <img src={ripple} style={{ marginTop: "10px", marginBottom: "15px", width: "55%" }} />
                                    <h6>Best for Starters</h6>
                                </center>
                                <div className='garamond-text'><span className='round-circle'></span>Get featured in Rift first local magazine cover page</div>
                                <div className='garamond-text'><span className='round-circle'></span>Detailed article on your success story</div>
                                <div className='garamond-text'><span className='round-circle'></span>Be a part of Rift first Prime Club</div>
                                <div className='garamond-text'><span className='round-circle'></span>Be part of workshops with journey experts</div>
                                <div className='garamond-text'><span className='round-circle'></span>Access to market research for your industry</div>
                                <div className='garamond-text'><span className='round-circle'></span>Mock pitching with experts</div>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="card-tech">
                                <center><div className="ripple-text">Wave</div>
                                    <img src={wave} style={{ marginTop: "10px", marginBottom: "15px", width: "55%" }} />
                                    <h6>Best for Inception</h6></center>
                                <div className='garamond-text'><span className='round-circle'></span>Discounted price for article in Rift first magazine</div>
                                <div className='garamond-text'><span className='round-circle'></span>Access to online and offline meetups and workshops</div>
                                <div className='garamond-text'><span className='round-circle'></span>Support in launching your startup</div>
                                <div className='garamond-text'><span className='round-circle'></span>Connect with branding and development partners for your product</div>
                                <div className='garamond-text'><span className='round-circle'></span>Support to financial analysis</div>
                                <div className='garamond-text'><span className='round-circle'></span>Access to pitch deck templates</div>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="card-tech">
                                <center><div className="ripple-text">Tide</div>
                                    <img src={tide} style={{ marginTop: "10px", marginBottom: "15px", width: "55%" }} />
                                    <h6>Best for Angle and above</h6></center>
                                <div className='garamond-text'><span className='round-circle'></span>Get featured in Rift first local magazine cover page</div>
                                <div className='garamond-text'><span className='round-circle'></span>Detailed article on your success story</div>
                                <div className='garamond-text'><span className='round-circle'></span>Be a part of Rift first Prime Club</div>
                                <div className='garamond-text'><span className='round-circle'></span>Be part of workshops with journey experts</div>
                                <div className='garamond-text'><span className='round-circle'></span>Access to market research for your industry</div>
                                <div className='garamond-text'><span className='round-circle'></span>Mock pitching with experts</div>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="card-tech">
                                <center><div className="ripple-text">Rogue</div>
                                    <img src={rogue} style={{ marginTop: "10px", marginBottom: "15px", width: "45%" }} />
                                    <h6>Best for Series A and above</h6></center>
                                <div className='garamond-text'><span className='round-circle'></span>Get featured in Rift first global magazine cover page</div>
                                <div className='garamond-text'><span className='round-circle'></span>Detailed article on your success story</div>
                                <div className='garamond-text'><span className='round-circle'></span>Be a part of Rift first Elite Community Club</div>
                                <div className='garamond-text'><span className='round-circle'></span>Personalised workshops with journey experts</div>
                                <div className='garamond-text'><span className='round-circle'></span>Access to market research for your product and industry</div>
                                <div className='garamond-text'><span className='round-circle'></span>Mock pitching with experts</div>
                            </Card>
                        </Col>
                    </Row >
                </Container ><br />
                <Footer />

            </div >

        </div >
    );
}

export default NextPage;
