import React, { useState, useEffect } from 'react';
import { Button, Navbar, Container, OverlayTrigger, Tooltip, Card, Row, Col } from "react-bootstrap";
import enable from '../../images/enable.png';
import inspire from '../../images/inspire1.png';
import achieve from '../../images/achieve.png';
import ripple from '../../images/Ripple.svg';
import wave from '../../images/Wave.svg';
import tide from '../../images/Tide.svg';
import rogue from '../../images/Rogue.svg';
import bridge from '../../images/sailor wheel.svg';
import deck from '../../images/Sunrise3.svg';
import cargo from '../../images/cargo alone.svg';
import NavBar from '../NavBar/NavBar';
import Footer from './Footer';
import './LandingPage.css';
import LoadingScreen from '../Loader/LoadingScreen';

function LandingPage() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 3000);
    }, [])

    if (loading) return <LoadingScreen />
    return (
        <>
            <NavBar />
            <div className='container-'>
                <div id="Home" className='section' >
                    <div className='content enable-center loading-animation'>
                        <Row style={{ width: "100%" }}>
                            <Col md={6}>
                                <center><img src={enable} className="enable-img" /></center>
                            </Col>
                            <Col md={6} className="flex flex-col justify-center items-end">
                                <div className='l-text'>Enable.</div>
                                <div className='l-text-sm'>The World is your playground...</div>
                                <div className='l-text-sm'>Bon voyage!</div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='section' >
                    <div class="content enable-center">
                        <Row style={{ width: "100%" }}>
                            <Col md={6}>
                                <img src={inspire} className="inspire-img" />
                            </Col>
                            <Col md={6} className="flex flex-col justify-center items-end">
                                <div className='l-text'>Inspire.</div>
                                <div className='l-text-sm'>Inspiring great minds in their</div>
                                <div className='l-text-sm'>amazing journeys</div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='section'>
                    <div className="content enable-center">
                        <Row style={{ width: "100%" }}>
                            <Col md={6}>
                                <center> <img src={achieve} className="achieve-img" /></center>
                            </Col>
                            <Col md={6} className="flex flex-col justify-center items-end">
                                <div className='l-text'>Achieve.</div>
                                <div className='l-text-sm'>A great tale to be told for</div>
                                <div className='l-text-sm'>generations to come</div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='section'>
                    <Row id="Industry" className="c-focus no-gutters">
                        <Col md={6} lg={6} className="bg-black text-white flex items-center justify-center">
                            <div>
                                <h1 className='text-base'>Industry</h1>
                                <h1 className='text-xl'>Focus</h1>
                            </div>
                        </Col>
                        <Col md={6} sm={6} lg={6}>
                            <div class="row no-gutters" >
                                <Col xs={6} md={6} lg={6}>
                                    <div className='deep-tech d-flex'> Deep Tech</div>
                                </Col>
                                <Col xs={6} md={6} lg={6}>
                                    <div className='deep-tech d-flex'>
                                        Manufacturing</div>
                                </Col>
                            </div>
                            <div class="row no-gutters">
                                <Col xs={4} md={4} lg={4}>
                                    <div className='deep-tech d-flex'>Agri Tech</div>
                                </Col>
                                <Col xs={8} md={8} lg={8}>
                                    <div className='deep-tech d-flex'>
                                        Green Tech</div>
                                </Col>
                            </div>
                            <div class="row no-gutters">
                                <Col xs={4} md={4} lg={4}>
                                    <div className='deep-tech d-flex'>Food Tech</div>
                                </Col>
                                <Col xs={4} md={4} lg={4}>
                                    <div className='deep-tech d-flex'>
                                        Gaming</div>
                                </Col>
                                <Col xs={4} md={4} lg={4}>
                                    <div className='deep-tech d-flex'>
                                        DeFi & Fintech</div>
                                </Col>
                            </div>
                        </Col>
                    </Row >
                </div>
                <div className='section'>
                    <Row id="Offering" className="c-center investor-focus no-gutters">
                        <Col xs={12} sm={12} md={4} lg={4} className='highlight-tech d-flex'>
                            <div >
                                <span className="circle-green"></span><span> Investor Offering</span><span className="text-Align"><span id="pre-content">(Navigate)</span></span><br />
                                <span className="circle-yellow"></span><span> Entreprenurial Offering</span><span className="text-Align"><span id="pre-content1">(Expedition)</span></span>
                            </div>
                        </Col>
                        <Col md={1}></Col>

                        <Col xs={12} sm={12} md={6} lg={6}>
                            <div class="row no-gutters" >
                                <div>
                                    <div className="expenditure-ml">
                                        {/* <div class="myDIV cursor">Hover over me.</div>
                                        <div class="hide">I am shown when someone hovers over the div above.</div> */}
                                        <div>Let Rift first be a part of your entrepreneurial <span id="i-content">journey,</span> let's set on an &nbsp;
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        <div className='left-align'>
                                                            <strong>ex.pe.di.tion</strong><br />
                                                            <span>/ɛkspɪˈdɪʃ(ə)n/ noun</span></div>
                                                        <hr style={{ color: "black" }} />
                                                        <div className='best-text'>
                                                            Rift First entrepreneurial offerings<br />
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="highlight-container"><span class="highlight">Expendition &nbsp;</span></span>
                                            </OverlayTrigger><br />
                                            through seas oceans and mountains alike. Starting <span id="pre-content">with</span> a &nbsp;
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        <div className='left-align'>
                                                            <strong>rip.ple</strong><br />
                                                            <span>/ˈrɪp(ə)l/ verb</span><img src={ripple} className="tooltip_img" /></div>
                                                        <hr style={{ color: "black" }} />
                                                        <div className='best-text'>1. Best for starters<br />
                                                            2. Basic offering to kick start</div>
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="highlight-container"><span class="highlight">Ripple &nbsp;</span></span>
                                            </OverlayTrigger>in the sea a baby step but very<span id="pre-content"> important.</span> As we then start our swim across
                                            <span id="pre-content"> hoping</span> to touch the horizon.</div>
                                        <div className='mt-2'>
                                            Standing at the &nbsp;
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        <div className='left-align'>
                                                            <strong>port</strong><br />
                                                            <span>/pɔːt/ verb</span><img src={cargo} className="tooltip_img2" /></div>
                                                        <hr style={{ color: "black" }} />
                                                        <div className='best-text'>1. Ready to fund from pool or personal<br />
                                                            2. Best for angel or venture level investor</div>
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="highlight-container-g"><span class="highlight">Port &nbsp;</span></span>
                                            </OverlayTrigger> while &nbsp;
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        <div className='left-align'>
                                                            <strong>tide</strong><br />
                                                            <span>/tʌɪd/ verb</span><img src={wave} className="tooltip_img" /></div>
                                                        <hr style={{ color: "black" }} />
                                                        <div className='best-text'>1. Best for inception<br />
                                                            2. Best to prototype business plan</div>
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="highlight-container"><span class="highlight">Tides &nbsp;</span></span>
                                            </OverlayTrigger>
                                            splash over.</div>
                                        With ever building excitement as our ship closes by.
                                        <div className='mt-2'>Hopping on to the &nbsp;
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        <div className='left-align'>
                                                            <strong>bridge</strong><br />
                                                            <span>/brɪdʒ/ verb</span><img src={bridge} className="tooltip_img1" /></div>
                                                        <hr style={{ color: "black" }} />
                                                        <div className='best-text'>1. Set capital for established companies<br />
                                                            2. Best for private equity and HNIs</div>
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="highlight-container-g"><span class="highlight">Bridge &nbsp;</span></span>
                                            </OverlayTrigger> as we &nbsp;
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        <div className='left-align'>
                                                            <strong>nav.i.gate</strong><br />
                                                            <span>/ˈnavɪɡeɪt/ noun</span></div>
                                                        <hr style={{ color: "black" }} />
                                                        <div className='best-text'>Rift First investor offerings</div>
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="highlight-container-g"><span class="highlight">Navigate &nbsp;</span></span>
                                            </OverlayTrigger> through the <span id="pre-content">raging</span> &nbsp;
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        <div className='left-align'>
                                                            <strong>wave</strong><br />
                                                            <span>/weɪv/ verb</span><img src={tide} className="tooltip_img" /></div>
                                                        <hr style={{ color: "black" }} />
                                                        <div className='best-text'>1. Best for Angle and above<br />
                                                            2. Gain from Rift First Prime Club</div>
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="highlight-container"><span class="highlight">Waves &nbsp;</span></span>
                                            </OverlayTrigger> with our new found motivation.</div>
                                        <div className='mt-2'>The Strom approaches bringing with it thick clouds <span id="pre-content">and </span>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        <div className='left-align'>
                                                            <strong>rogue</strong><br />
                                                            <span>/rəʊɡ/ verb</span><img src={rogue} className="tooltip_img" /></div>
                                                        <hr style={{ color: "black" }} />
                                                        <div className='best-text'>1. Best for Series A and above<br />
                                                            2. Get access to Rift First Elite Club</div>
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="highlight-container"><span class="highlight">Rogue &nbsp;</span></span>
                                            </OverlayTrigger> waves, but we push forward  fuelled by
                                            <span id="pre-content">our</span> determination. Manoeuvring and pushing
                                            <span id="pre-content">through</span> the storm, fallen we might have many a
                                            <span id="pre-content">times</span> but raise again we did a plenty.</div>
                                        <div className='mt-2'>As the Strom settles we look at the beautiful sun raise  <span id="pre-content">from</span> the &nbsp;
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        <div className='left-align'>
                                                            <strong>dock</strong><br />
                                                            <span>/dɒk/ verb</span><img src={deck} className="tooltip_img" /></div>
                                                        <hr style={{ color: "black" }} />
                                                        <div className="best-text">1. Be investor mentor to grow companies<br />
                                                            2. Best for early or late stage VCs</div>
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="highlight-container-g"><span class="highlight">Dock &nbsp;</span></span>
                                            </OverlayTrigger>
                                            bringing with it new possibilities
                                            <span id="pre-content">and</span> opportunities</div>


                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={2}>

                        </Col>

                        <Col md={1}></Col>
                    </Row>
                </div >

                <div className='section'>
                    <Footer />
                </div>
            </div >
            {/* <Footer /> */}

        </>
    );
}

export default LandingPage;
