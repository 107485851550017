import { React, Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button, Navbar } from "react-bootstrap";
import logo from '../../images/logo-new.png';
import './NavBar.css';

export default class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = { clicked: false }
    }
    handleClick = () => {
        this.setState({
            clicked: !this.state.clicked
        })
    }
    render() {
        return (
            <Navbar className="navbar-height" fixed="top">
                <nav>
                    <div className="menu-icon" onClick={this.handleClick}>
                        <i className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
                    </div>
                    {<div className={this.state.clicked ? 'nav-menu active opacanimation' : 'nav-menu'}>
                        <ul>
                            <li><a onClick={this.handleClick} href="#Home" className='nav-links'>Home</a></li>
                            <li><a onClick={this.handleClick} href="#Industry" className='nav-links'>Industry</a></li>
                            <li><a onClick={this.handleClick} href="#Offering" className='nav-links'>Offering</a></li>
                            <li><a href="/symphonyfund" className='nav-links'>Symphony fund</a></li>
                            <li><a onClick={this.handleClick} href="#Contact" className='nav-links'>Contact</a></li>
                        </ul>
                    </div>
                    }

                    <Link to="/">
                        <img className="nav-logo" src={logo} /></Link>
                </nav>
            </Navbar>
        )
    }
}
