import { React, Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Form, Button, Container } from "react-bootstrap";
import bottomicon from '../../images/footer-bottom.png';
import world from '../../images/world_grey.svg';
import './Footer.css';

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            name: "",
            subject: "",
            message: "",
            messageSubject: ""
        }
    }
    handleSubmit = (e) => {
        e.preventDefault()
    }
    onChange = (event) => {
        // const { name, value } = event.target;
        console.log("fcgvbh")
        this.setState({
            [event.target.name]: event.target.value
        });
        return;
    }
    render() {
        return (
            <div id="Contact" className="footer_bg">
                <div className="footer-top">
                    <Row style={{ width: "100%" }} >
                        <Col xs={12} sm={12} lg={6}>
                            <div className='say-hello'>Say hello</div>
                            <Form onSubmit={this.handleSubmit}>
                                <Row className="top-gap">
                                    <Col xs={12} sm={12} lg={6}>
                                        <div id="border" class="input-border">
                                            <input type="text" id="text" name="name" onChange={this.onChange} class="text" required />
                                            <label className="label">Full Name</label>
                                            <div class="border"></div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} lg={6}>
                                        <div id="border" class="input-border">
                                            <input type="text" id="email" name="email" onChange={this.onChange} class="text" required />
                                            <label className="label">Email</label>
                                            <div class="border"></div>
                                        </div>
                                    </Col>
                                </Row>
                                <div>
                                    <div id="border" class="input-border">
                                        <select name="subject" value={this.state.subject} defaultValue={"Entrepreneurer"} onChange={this.onChange} class="text" required >
                                            <option value="Entrepreneurer">Entrepreneurer</option>
                                            <option value="Investor">Investor</option>
                                            <option value="Enquiry">Enquiry</option>

                                        </select>

                                    </div>
                                    <div id="border" class="input-border">
                                        <input type="text" id="text" name="messageSubject" onChange={this.onChange} class="text" required />
                                        <label className="label">Message Subject</label>
                                        <div class="border"></div>
                                    </div>
                                    <Form.Group>
                                        <textarea style={{ background: "#efefed", overflowY: "none", resize: "none" }} name="message" onChange={this.onChange} rows={4} placeholder="Message" className='form-control' />
                                    </Form.Group>
                                    {/* <div className='form-control'>
                                        <textarea type="text" id="text" name="message" rows={5} onChange={this.onChange} />
                                        <label className="label">Message</label>
                                    </div> */}
                                </div>
                                <Button variant="default" type="submit" className="submit-btn">Send</Button>
                            </Form>
                        </Col>
                        {/* <Col xs={12} md={1}></Col> */}
                        <Col xs={12} sm={12} lg={6}>
                            <div className='image-top-gap'>
                                <img src={world} className="world-img" />

                            </div>
                        </Col>

                    </Row>

                    <img src={bottomicon} className="bottom-icon" />
                    <div className='pt-1'>
                        <hr className='bg-hr' />
                        <center className="copyright-text">
                            Rift First Capital. Copyright 2022. All right reserved.
                        </center>
                    </div>
                </div>
            </div>
        )
    }
}

// <div id="Contact" className="footer_bg p-4">
// <Row style={{ width: "100%" }} className="footer-top">
//     <Col xs={12} md={5}>
//         <img src={logo} className="footer-logo" /><br />
//         <div className='mt-2 footer-text'>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//             Interdum vestibulum cum sit non lobortis potenti praesent
//             egestas. Sed nunc eu et erat odio ultrices augue enim.
//         </div>
//     </Col>
//     <Col xs={12} md={1}></Col>
//     <Col xs={12} md={6}>
//         <Row style={{ width: "100%" }}>
//             <Col xs={4} md={4}>
//                 <a> <p className='mt-5 links-sm'>About</p></a>
//                 <a> <p className='links-sm'>Features</p></a>
//                 <a> <p className='links-sm'>Careers</p></a>
//                 <a> <p className='links-sm'>FAQs</p></a>
//             </Col>
//             <Col xs={4} md={4}>
//                 <a> <p className='mt-5 links-sm'>Products</p></a>
//                 <a> <p className='links-sm'>Partnerships</p></a>
//                 <a> <p className='links-sm'>Policies</p></a>
//                 <a> <p className='links-sm'>Help</p></a>
//             </Col>
//             <Col xs={4} md={4}>
//                 <a> <p className='mt-5 links-sm'>Linkedin</p></a>
//                 <a> <p className='links-sm'>Facebook</p></a>
//                 <a> <p className='links-sm'>Instagram</p></a>
//                 <a> <p className='links-sm'>Twitter</p></a>
//             </Col>
//         </Row>
//     </Col>
// </Row>
// <hr className='bg-white' />

// <div className=' footer-text pt-2'>
//     <center>
//         Copyright 2022 Rift First. All right reserved.
//     </center>
// </div>
// </div>