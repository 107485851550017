import React from 'react'

function LoadingScreen() {
    return (
        <div className='min-h-screen flex flex-col items-center justify-center loading-animation'>
            {/* <h1 className='text-xl'>RIFT FIRST</h1> */}
            <h1 style={{ textAlign: 'center' }} className="text-base">Enable. Inspire. Achieve.</h1>
        </div>
    )
}

export default LoadingScreen