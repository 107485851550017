import React, { useState } from 'react';
import { Button, Navbar, Container, Card, Row, Col } from "react-bootstrap";
import Header from '../Header/Header';
import './LandingPage.css';
import AnimatedNumbers from "react-animated-numbers";
import inspire from '../../images/inspire.PNG';
import NavBar from '../NavBar/NavBar';

function SymphonyFund() {
    const [num, setNum] = useState(1960)
    return (
        <div>
            <Header />
            <div className='container-'>
                <div className='section' >
                    <div class="c-symphony">
                        <p className="mt-3 symphony-text">Symphony Fund</p>
                        <div className="animated-container"><span className="symphony-amount">$</span>
                            <AnimatedNumbers
                                includeComma={true}
                                animateToNumber={num}
                            />
                        </div>
                        <div className="updated-weekly">* Updated weekly</div>
                        <br />
                        <div className="flex flex-col justify-center symphony-text-sm">
                        <i>A collection of funds from various resources pooled together to create the perfect symphony. Enabling dreamers, innovators and teams who dare to create something new.</i>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default SymphonyFund;
