import { React, Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button, Navbar } from "react-bootstrap";
import logo from '../../images/logo-new.png';
import '../NavBar/NavBar.css';

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = { clicked: false }
    }
    handleClick = () => {
        this.setState({
            clicked: !this.state.clicked
        })
    }
    render() {
        return (
            <Navbar className="navbar-height" fixed="top">
                <nav>
                    <a href="/" className="menu-icon" >
                        <i className={'fas fa-home'}></i>
                    </a>
                   

                    <Link to="/">
                        <img className="nav-logo" src={logo} /></Link>
                </nav>
            </Navbar>
        )
    }
}
