import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import LoadingScreen from './components/Loader/LoadingScreen';
import LandingPage from './components/LandingPage/LandingPage';
import SymphonyFund from './components/LandingPage/SymphonyFund';
import NextPage from './components/LandingPage/NextPage';

export default function Routes() {
    // const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setLoading(false)
    //     }, 3000);
    // }, [])

    // if (loading) return <LoadingScreen />

    return (
        <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/symphonyfund" component={SymphonyFund} />
            <Route path="/next" component={NextPage} />
        </Switch >
    )
}
